export const newRecordPath =
  process.env.REACT_APP_PATH_TO_UVICORN + "new_record";
export const recordsPath = process.env.REACT_APP_PATH_TO_UVICORN + "records";
export const featuresPath = {
  names: process.env.REACT_APP_PATH_TO_UVICORN + "names",
  sources: process.env.REACT_APP_PATH_TO_UVICORN + "sources",
  services: process.env.REACT_APP_PATH_TO_UVICORN + "services",
  reasons: process.env.REACT_APP_PATH_TO_UVICORN + "reasons",
};
export const namesPath = process.env.REACT_APP_PATH_TO_UVICORN + "names";
