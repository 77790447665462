import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  RecordType,
  RecordSliceType,
  serializeRecordType,
  deserializeRecordType,
} from "./types";
import { newRecordPath, featuresPath, recordsPath } from "./routes";
import { Dayjs } from "dayjs";

const initialState: RecordSliceType = {
  record: {
    datetime: dayjs(),
    info: "",
    name: "",
    service: "",
    source: "",
    timestamp: dayjs(),
    status: "pending",
    finished: "false",
    reason: "",
    income: 0,
  },
  editMode: false,
  success: false,
  names: [],
  services: [],
  sources: [],
  reasons: [],
  loading: false,
  error: null,
  recordName: "",
  recordService: "",
  records: [],
  needUpdateTable: true,
  recordsToDelete: [],
  nextToSelectedDayRecordsId: [],
  rejectedIds: [],
  selectedDay: dayjs().startOf("day"),
  rejectDialogOpen: false,
  archiveDialogOpen: false,
  successArchive: false,
};

export const putRecord = createAsyncThunk(
  "record",
  async (record: RecordType) => {
    return await fetch(newRecordPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: serializeRecordType(record),
    }).then((r) => r.json().then((r) => deserializeRecordType(r)));
  }
);

export const getRecords = createAsyncThunk(
  "records/get",
  async (timestamp: number | null = null) => {
    let path = recordsPath;
    if (timestamp)
      path += "?" + new URLSearchParams({ timestamp: `${timestamp}` });
    return await fetch(path, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((r) =>
      r.json().then((r) => r.map((_) => deserializeRecordType(_)))
    );
  }
);

export const deleteRecords = createAsyncThunk(
  "records/delete",
  async (ids: Array<number>) => {
    return await fetch(recordsPath, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(ids),
    })
      .then((r) => r.json())
      .then((r) => r);
  }
);

export const moveRecord = createAsyncThunk(
  "record/move",
  async (record: RecordType) => {
    return await fetch(
      newRecordPath + "?" + new URLSearchParams({ id: `${record.id}` }),
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: serializeRecordType(record),
      }
    ).then((r) => r.json().then((r) => deserializeRecordType(r)));
  }
);

export const getFeatures = createAsyncThunk(
  "names",
  async (feature: string) => {
    return await fetch(featuresPath[feature], {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((r) => r.json())
      .then((r) => {
        r.type = feature;
        return r;
      });
  }
);

export const newRecordSlice = createSlice({
  name: "newRecord",
  initialState,
  reducers: {
    setRecordField: (state, { payload: { field, value } }) => {
      state.record[field] = value;
    },

    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    setReason: (state, { payload }) => {
      state.reasons = payload;
    },
    setSuccessArchive: (state, { payload }) => {
      state.successArchive = payload;
    },
    setNeedUpdateTable: (state, { payload }) => {
      state.needUpdateTable = payload;
    },
    setSelection: (state, { payload }) => {
      state.recordsToDelete = payload;
    },
    setSelectedDay: (state, { payload }) => {
      state.selectedDay = payload;
      const nextDay = state.selectedDay.add(1, "days").startOf("day");
      state.nextToSelectedDayRecordsId = state.records
        .filter((r) => r.datetime.isBefore(nextDay))
        .map((r) => r.id);
    },
    setEditMode: (state, { payload }) => {
      state.editMode = payload;
    },
    setRejectDialogOpen: (state, { payload }) => {
      state.rejectDialogOpen = payload;
    },
    setArchiveDialogOpen: (state, { payload }) => {
      state.archiveDialogOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(putRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(putRecord.fulfilled, (state, action) => {
        const payload = action.payload as RecordType;
        state.record.datetime = payload.datetime;
        state.record.info = "";
        state.record.name = "";
        state.record.service = "";
        state.record.source = "";
        state.record.reason = "";
        state.record.income = 0;
        state.loading = false;
        state.success = true;
        state.recordName = payload.name;
        state.recordService = payload.service;
        state.needUpdateTable = true;
      })
      .addCase(putRecord.rejected, (state, action) => {
        state.error = action.error as string;
      })
      .addCase(moveRecord.pending, (state) => {
        state.loading = true;
      })
      .addCase(moveRecord.fulfilled, (state, action) => {
        const payload = action.payload as RecordType;
        state.record.datetime = payload.datetime;
        state.record.info = "";
        state.record.id = -1;
        state.record.name = "";
        state.record.service = "";
        state.record.source = "";
        state.record.reason = "";
        state.record.income = 0;
        state.loading = false;
        if (state.record.finished === "true") state.successArchive = true;
        else state.success = true;
        state.recordName = payload.name;
        state.recordService = payload.service;
        state.needUpdateTable = true;
        state.editMode = false;
      })
      .addCase(moveRecord.rejected, (state, action) => {
        state.error = action.error as string;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state[action.payload.type] = action.payload.map((e) => e.name);
      })
      .addCase(getRecords.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecords.fulfilled, (state, action) => {
        const payload = action.payload;
        // state.records = payload.filter((r) => r.status !== "rejected");
        state.records = payload.filter((r) => r.finished !== "true");
        state.rejectedIds = payload
          .filter((r) => r.status === "rejected")
          .map((r) => r.id);
        const nextDay = state.selectedDay.add(1, "days").startOf("day");
        state.nextToSelectedDayRecordsId = state.records
          .filter((r) => r.datetime.isBefore(nextDay))
          .map((r) => r.id);
        state.loading = false;
      })
      .addCase(getRecords.rejected, (state, action) => {
        state.error = action.error as string;
      })
      .addCase(deleteRecords.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRecords.fulfilled, (state) => {
        state.loading = false;
        state.needUpdateTable = true;
      });
  },
});

export const dateIsBeforCurrentDate = (state) => {
  const datetime = state.newRecord.record.datetime;
  const isRejected = state.newRecord.record.status === "rejected";
  return datetime
    ? datetime.isBefore(dayjs().add(-60 * 24, "minute")) || isRejected
    : false;
};

export const numRecordsInDay = (
  state,
  startDay: Dayjs,
  numDays: number
): Record<number, number> => {
  const endDay = startDay.add(numDays, "day");
  startDay = startDay.add(-numDays, "day");
  if (state.newRecord.records.length === 0) return {};

  let date = startDay.clone();
  const numRecordsPerDay = {};
  while (date.isBefore(endDay)) {
    const recordsPerDay = state.newRecord.records.filter(
      (r) =>
        r.datetime.isAfter(date.startOf("day")) &&
        r.datetime.isBefore(date.endOf("day")) &&
        r.status !== "rejected"
    );
    numRecordsPerDay[date.unix()] = recordsPerDay.length;
    date = date.add(1, "day");
  }
  return numRecordsPerDay;
};

export const {
  setRecordField,
  setLoading,
  setSuccess,
  setSuccessArchive,
  setNeedUpdateTable,
  setSelection,
  setSelectedDay,
  setEditMode,
  setRejectDialogOpen,
  setArchiveDialogOpen,
} = newRecordSlice.actions;

export default newRecordSlice.reducer;
