import React from 'react';
import 'dayjs/locale/ru';
import { Alert, Snackbar } from '@mui/material';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { setSuccess } from '../../store/newRecordSlice';

const Alerter = () => {
  const dispatch = useAppDispatch();

  const { datetime } = useAppSelector((state) => state.newRecord.record);
  const { success, recordName, recordService } = useAppSelector(
    (state) => state.newRecord
  );

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSuccess(false));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        top: {
          xs: 0.85 * window.document.body.offsetHeight,
          sm: 0.85 * window.document.body.offsetHeight,
        },
      }}
      open={success}
      autoHideDuration={2000}
      onClose={handleCloseSuccess}
    >
      <Alert onClose={handleCloseSuccess} severity="success">
        {`${
          recordName && recordName[0].toUpperCase() + recordName.slice(1)
        } записана на ${
          recordService &&
          recordService[0].toLowerCase() + recordService.slice(1)
        } на ${
          datetime && datetime.locale('ru').format('dd, DD.MM.YY, HH:mm')
        }`}
      </Alert>
    </Snackbar>
  );
};

export default Alerter;
