import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useAppSelector from "../../hooks/useAppSelector";
import { setArchiveDialogOpen } from "../../store/newRecordSlice";
import useAppDispatch from "../../hooks/useAppDispatch";
type AlertDialogProps = {
  title: string;
  content: string;
  onSuccess: () => void;
};
export default function ArchiveDialog(props: AlertDialogProps) {
  const { title, content, onSuccess } = props;
  const dispatch = useAppDispatch();
  const archiveDialogOpen = useAppSelector(
    (state) => state.newRecord.archiveDialogOpen
  );

  const handleClose = () => {
    dispatch(setArchiveDialogOpen(false));
  };

  return (
    <div>
      <Dialog
        open={archiveDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Назад</Button>
          <Button onClick={onSuccess}>ОК</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
