import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DateSelector from "./DateSelector";
const cssStyle = {
  margin: 2,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const AddRecord = () => {
  return (
    <Box>
      <Typography component="h1" variant="h5">
      <Box sx={cssStyle}>
        Введите дату и время
        </Box>
      </Typography>
      <DateSelector />
    </Box>
  );
};

export default AddRecord;
