import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import { Autocomplete, Button, ButtonGroup } from '@mui/material';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import {
  dateIsBeforCurrentDate,
  moveRecord,
  setArchiveDialogOpen,
  setEditMode,
  setSelection,
} from '../../store/newRecordSlice';
import theme from '../../theme';
import {
  setRecordField,
  putRecord,
  getFeatures,
  setSuccess,
} from '../../store/newRecordSlice';
import { setPageId } from '../../store/appSlice';
import { darken } from '@material-ui/core';

const cssStyle = {
  margin: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

export default function DateSelector() {
  const dispatch = useAppDispatch();

  const { datetime, name, info, service, source, status, income, reason } =
    useAppSelector((state) => state.newRecord.record);

  const dateIsBefore = useAppSelector((state) => dateIsBeforCurrentDate(state));
  const { editMode } = useAppSelector((state) => state.newRecord);
  const { record, names, services, sources, reasons, success, needUpdateTable } =
    useAppSelector((state) => state.newRecord);

  useEffect(() => {
    dispatch(getFeatures('names'));
    dispatch(getFeatures('sources'));
    dispatch(getFeatures('services'));
    dispatch(getFeatures('reasons'));
  }, [success, needUpdateTable]);

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <Box sx={cssStyle}>
          <MobileDatePicker
            label="Дата записи"
            inputFormat="DD.MM.YYYY"
            value={datetime}
            disabled={dateIsBefore}
            onChange={(value) => {
              dispatch(setRecordField({ field: 'datetime', value }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
        <Box sx={cssStyle}>
          <MobileTimePicker
            label="Время записи"
            disabled={dateIsBefore}
            value={datetime}
            ampm={false}
            onChange={(value) => {
              dispatch(setRecordField({ field: 'datetime', value }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>
      </LocalizationProvider>
      <Box sx={cssStyle}>
        <Autocomplete
          disablePortal
          freeSolo={true}
          options={names}
          inputValue={name}
          onChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({ field: 'name', value: value.target.textContent })
            );
          }}
          onInputChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({ field: 'name', value: value.target.value })
            );
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Имя" />}
        />
      </Box>
      <Box sx={cssStyle}>
        <Autocomplete
          disablePortal
          freeSolo={true}
          inputValue={service}
          options={services}
          onChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({
                field: 'service',
                value: value.target.textContent,
              })
            );
          }}
          onInputChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({ field: 'service', value: value.target.value })
            );
          }}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Услуга" />}
        />
      </Box>
      <Box sx={cssStyle}>
        <Autocomplete
          disablePortal
          inputValue={source}
          freeSolo={true}
          options={sources}
          onChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({
                field: "source",
                value: value.target.textContent,
              })
            );
          }}
          onInputChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({ field: "source", value: value.target.value })
            );
          }}
          sx={{ width: 300 }}
          disabled={dateIsBefore}
          renderInput={(params) => <TextField {...params} label="Источник" />}
        />
      </Box>

      {dateIsBefore && (
        <Box sx={cssStyle}>
          <Box sx={{ marginBottom: '5px' }}>Запись состоялась?</Box>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              sx={{
                backgroundColor:
                  status === 'rejected' || status === 'pending'
                    ? theme.palette.secondary.main
                    : darken(theme.palette.secondary.main, 0.3),
              }}
              onClick={() => {
                dispatch(setRecordField({ field: 'status', value: 'done' }));
              }}
            >
              Да
            </Button>
            <Button
              sx={{
                backgroundColor:
                  status === 'done' || status === 'pending'
                    ? theme.palette.secondary.main
                    : darken(theme.palette.secondary.main, 0.3),
              }}
              onClick={() => {
                dispatch(
                  setRecordField({ field: 'status', value: 'rejected' })
                );
              }}
            >
              Нет
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {status === 'rejected' && (
        <Box sx={cssStyle}>
          <Autocomplete
            disablePortal
            freeSolo={true}
            inputValue={reason || ''}
            options={[]}
            onChange={(
              value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              dispatch(
                setRecordField({
                  field: 'reason',
                  value: value?.target?.textContent || '',
                })
              );
            }}
            onInputChange={(
              value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              console.log();
              dispatch(
                setRecordField({ field: 'reason', value: value?.target?.value })
              );
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Причина" />}
          />
        </Box>
      )}
      {status === 'done' && (
        <Box sx={cssStyle}>
          <TextField
            inputProps={{ type: 'number' }}
            id="filled-basic"
            label="Доход"
            value={income}
            sx={{ width: 150 }}
            onChange={(
              value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              dispatch(
                setRecordField({ field: 'income', value: value.target.value })
              );
            }}
          />
        </Box>
      )}
      <Box sx={cssStyle}>
        <TextField
          id="filled-basic"
          label="Инфо"
          value={info}
          multiline
          rows={4}
          sx={{ width: 300 }}
          onChange={(
            value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            dispatch(
              setRecordField({ field: 'info', value: value.target.value })
            );
          }}
        />
      </Box>
      <Box sx={cssStyle}>
        {!editMode && (
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() => {
              dispatch(putRecord(record));
              dispatch(setPageId(0));
            }}
          >
            Добавить запись
          </Button>
        )}
        {editMode && dateIsBefore && (
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() => {
              dispatch(setArchiveDialogOpen(true));
              dispatch(setEditMode(false));
            }}
          >
            В архив
          </Button>
        )}
        {editMode && !dateIsBefore && (
          <Button
            variant="contained"
            sx={{ backgroundColor: theme.palette.secondary.main }}
            onClick={() => {
              dispatch(moveRecord(record));
              dispatch(setPageId(0));
              dispatch(setEditMode(false));
            }}
          >
            Перезаписать
          </Button>
        )}
        {
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.secondary.light,
              margin: '10px',
            }}
            onClick={() => {
              dispatch(setPageId(0));
              dispatch(setEditMode(false));
              if (editMode) {
                dispatch(setRecordField({ field: 'info', value: '' }));
                dispatch(setRecordField({ field: 'id', value: -1 }));
                dispatch(setRecordField({ field: 'name', value: '' }));
                dispatch(setRecordField({ field: 'service', value: '' }));
                dispatch(setRecordField({ field: 'source', value: '' }));
                dispatch(setRecordField({ field: 'info', value: '' }));
                dispatch(setRecordField({ field: 'reason', value: '' }));
                dispatch(setRecordField({ field: 'income', value: 0 }));
                setSelection([]);
              }
            }}
          >
            Назад
          </Button>
        }
      </Box>
    </Container>
  );
}
