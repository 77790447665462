import { AppState } from "./types";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AppState = {
  loading: false,
  error: false,
  pageId: 0,
  isMenuOpen: false,
  isGarmentSelectOpen: false,
  patterns: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setPageId: (state, { payload }) => {
      state.pageId = payload;
    },
  },
});

export const { setLoading, setError, setPageId } = appSlice.actions;

export default appSlice.reducer;
