import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import newRecordSlice from "./newRecordSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    newRecord: newRecordSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
