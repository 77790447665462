import { createTheme } from '@mui/material/styles';
const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#ab98dd',
    },
    secondary: {
      main: '#cbdd98',
    },
    background: {
      default: '#FFFFFF',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    success: {
      main: '#72991e',
    },
  },
  
};

export default createTheme(themeOptions);