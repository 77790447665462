import dayjs, { Dayjs } from "dayjs";

export type AppState = {
  loading: boolean;
  error: boolean;
  pageId: number;
  isMenuOpen: boolean;
  isGarmentSelectOpen: boolean;
  patterns: Array<string>;
};

export type RecordSliceType = {
  record: RecordType;
  loading: boolean;
  error: string | null;
  names: Array<Record<string, string>>;
  services: Array<Record<string, string>>;
  sources: Array<Record<string, string>>;
  reasons: Array<Record<string, string>>;
  success: boolean;
  recordName: string;
  recordService: string;
  records: Array<RecordType>;
  needUpdateTable: boolean;
  recordsToDelete: Array<number>;
  nextToSelectedDayRecordsId: Array<number>;
  selectedDay: Dayjs;
  editMode: boolean;
  rejectedIds: Array<number>;
  rejectDialogOpen: boolean;
  archiveDialogOpen: boolean;
  successArchive: boolean;
};

export type RecordType = {
  datetime: Dayjs;
  name: string;
  source: string;
  service: string;
  info: string;
  timestamp: Dayjs;
  id?: number;
  status: string;
  income?: number;
  reason?: string;
  finished: string;
};

export type SerialiazedRecordType = {
  datetime: Dayjs;
  name: string;
  source: string;
  service: string;
  info: string;
  timestamp: string;
  id?: number;
  status: string;
  income?: number;
  reason?: string;
  finished: string;
};

export function serializeRecordType(record: RecordType) {
  return JSON.stringify(
    Object.fromEntries(
      Object.entries(record).map(([key, value]) => {
        return [key, dayjs.isDayjs(value) ? value.unix() : value];
      })
    )
  );
}
export function deserializeRecordType(serializedRecord: object): RecordType {
  return Object.fromEntries(
    Object.entries(serializedRecord).map(([key, value]) => {
      return [key, key.includes("time") ? dayjs.unix(value as number) : value];
    })
  ) as RecordType;
}

export type TPages = "manageRecords" | "addRecord";
