import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import {
  MobileDateTimePicker as DateTimePicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
  StaticDatePicker,
} from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import { Button, styled } from '@mui/material';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';

import {
  getRecords,
  setNeedUpdateTable,
  setSelection,
  setSelectedDay,
  setRecordField,
  setEditMode,
  setRejectDialogOpen,
} from '../../store/newRecordSlice';
import { DataGrid, GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { setPageId } from '../../store/appSlice';
import { numRecordsInDay, moveRecord } from '../../store/newRecordSlice';
import { useSelector } from 'react-redux';
import theme from '../../theme';
import { ThemeProvider } from '@mui/material/styles';
import { lighten } from '@material-ui/core';
import { GRID_DATE_COL_DEF, useGridApiContext } from '@mui/x-data-grid';

const Dot = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: 0,
  width: 0,
  border: '2px solid',
  borderRadius: 4,
  // borderColor: "#000000",
  right: '50%',
  transform: 'translateX(1px)',
  top: '80%',
}));

export default function ManageRecords() {
  function GridEditDateCell({ id, field, value }) {
    const apiContext = useGridApiContext();
    function handleChange(newValue) {
      apiContext.current.setEditCellValue({ id, field, value: newValue });
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
        <DateTimePicker
          value={dayjs(value, 'dd, DD.MM.YY, HH:mm', 'ru')}
          renderInput={(params) => <TextField {...params} />}
          onChange={handleChange}
        />
      </LocalizationProvider>
    );
  }

  const dateColumnType = {
    ...GRID_DATE_COL_DEF,
    renderEditCell: (params) => <GridEditDateCell {...params} />,
  };
  const columns: GridColDef[] = [
    {
      field: 'datetimeView',
      ...dateColumnType,
      headerName: 'Дата',
      width: 180,
      editable: false,
    },
    { field: 'name', headerName: 'Имя', width: 130, editable: false },
    { field: 'service', headerName: 'Услуга', width: 250, editable: false },
    { field: 'source', headerName: 'Источник', width: 100, editable: false },
    { field: 'info', headerName: 'Инфо', width: 360, editable: false },
  ];

  const dispatch = useAppDispatch();
  const [patchRecord, setPatchRecord] = useState(false);
  const curRecord = useAppSelector((state) => state.newRecord.record);
  useEffect(() => {
    if (patchRecord) {
      dispatch(moveRecord(curRecord));
      setPatchRecord(false);
    }
  }, [dispatch, curRecord, patchRecord]);

  const selectedDay = useAppSelector((state) => state.newRecord.selectedDay);
  const rejectedIds = useAppSelector((state) => state.newRecord.rejectedIds);
  const nextToSelectedDayRecordsId = useAppSelector(
    (state) => state.newRecord.nextToSelectedDayRecordsId
  );

  const numRecordsPerDay = useSelector((state) => {
    return numRecordsInDay(state, dayjs().startOf('day'), 30);
  });

  const [doubleClick, setDoubleClick] = useState(0);
  const [doubleClick2, setDoubleClick2] = useState(0);

  const renderWeekPickerDay = (
    date: Dayjs,
    selectedDates: Array<Dayjs | null>,
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    pickersDayProps.onClick = () => {
      if (date.date() === doubleClick) {
        dispatch(setRecordField({ field: 'datetime', value: date }));
        dispatch(setPageId(1));
      } else {
        if (date.isBefore(dayjs().startOf('day'))) return;
        setDoubleClick(date.date());
        setTimeout(() => {
          setDoubleClick(0);
        }, 1500);
      }
    };
    const a = numRecordsPerDay;
    const recordNums = a[date.unix()];
    if (!recordNums || pickersDayProps.outsideCurrentMonth)
      return <PickersDay {...pickersDayProps} />;

    let positions = [];

    switch (recordNums) {
      case 1: {
        positions.push(50);
        break;
      }
      case 2: {
        positions.push(50 - 8, 50 + 8);
        break;
      }
      case 3: {
        positions.push(50 - 16, 50, 50 + 16);
        break;
      }
      case 4: {
        positions.push(50 - 24, 50 - 8, 50 + 8, 50 + 24);
        break;
      }
      case 5: {
        positions.push(50 - 24, 50 - 8, 50 + 8, 50 + 24, 50);
        break;
      }
      default: {
        positions.push(50 - 24, 50 - 8, 50 + 8, 50 + 24, 50 - 8, 50 + 8);
        break;
      }
    }
    const aa = date.locale('ru').format('dd, DD.MM.YY, HH:mm');
    const b = dayjs().locale('ru').format('dd, DD.MM.YY, HH:mm');
    const dateIsBefore = date.isBefore(dayjs().startOf('day'));
    return (
      <Box sx={{ position: 'relative', display: 'grid', gap: '4px' }}>
        <PickersDay {...pickersDayProps} />
        {positions.map((p, idx) => (
          <Dot
            sx={{
              right: `${p}%`,
              top: `${idx > 3 ? 95 : 75}%`,
              borderColor: !dateIsBefore ? '#000000' : '#CACACA',
            }}
          />
        ))}
      </Box>
    );
  };
  const records = useAppSelector((state) => {
    const records = state.newRecord.records;
    return [...records]
      .sort((a, b) => (a.datetime < b.datetime ? -1 : 1))
      .filter((r) => r.datetime.isAfter(selectedDay.startOf('day')))
      .map((r) => {
        const rObj = Object.fromEntries(Object.entries(r));
        const datetime = rObj.datetime as Dayjs;
        rObj.datetimeView = datetime.locale('ru').format('dd, DD.MM.YY, HH:mm');
        return rObj;
      });
  });

  const handleDateChange = (e) => {
    dispatch(setSelectedDay(e));
  };

  const { needUpdateTable, recordsToDelete } = useAppSelector(
    (state) => state.newRecord
  );

  useEffect(() => {
    if (needUpdateTable) {
      dispatch(getRecords(dayjs().add(-30, 'minute').unix()));
      dispatch(setNeedUpdateTable(false));
    }
  }, [needUpdateTable]);
  const handleOnCellClick = (e) => {
    if (e.id === doubleClick2) {
      const record = records.find((r) => r.id === e.id);
      // const date = dayjs.date ==record.date as Dayjs;
      // const dateIsBefore = date.isBefore(dayjs().add(60,'minute'));
      // if (dateIsBefore) {

      // }else {
      Object.entries(record).forEach(([field, value]) => {
        dispatch(setRecordField({ field, value }));
      });
      dispatch(setRecordField({ field: 'id', value: e.id }));
      dispatch(setPageId(1));
      dispatch(setEditMode(true));
      // }
    } else {
      setDoubleClick2(e.id as number);
      setTimeout(() => {
        setDoubleClick2(0);
      }, 1500);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: 500,
          width: '100%',
          '& .super-app-theme--Today-rejected': {
            background: lighten(theme.palette.error.light, 0.9),
          },
          '& .super-app-theme--Today': {},
          '& .super-app-theme--Tomorrow-rejected': {
            background: lighten(theme.palette.error.light, 0.9),
            color: lighten(theme.palette.text.secondary, 0.5),
          },
          '& .super-app-theme--Tomorrow': {
            color: lighten(theme.palette.text.secondary, 0.5),
          },
        }}
      >
        <Box sx={{ height: 290 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <StaticDatePicker
              renderDay={renderWeekPickerDay}
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={selectedDay}
              onChange={(e) => {
                handleDateChange(e);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Box>

        <Box sx={{ height: '50px' }}>
          <Button
            variant="contained"
            disabled={recordsToDelete.length === 0}
            sx={{
              backgroundColor: theme.palette.error.light,
              marginRight: '10px',
              marginBottom: '10px',
              display: recordsToDelete.length === 0 ? 'none' : undefined,
            }}
            onClick={() => {
              dispatch(setRejectDialogOpen(true));
            }}
          >
            Отменить
          </Button>
          <Button
            variant="contained"
            disabled={
              recordsToDelete.length === 0 || recordsToDelete.length > 1
            }
            sx={{
              backgroundColor: theme.palette.secondary.main,
              marginRight: '10px',
              marginBottom: '10px',
              display:
                recordsToDelete.length === 0 || recordsToDelete.length > 1
                  ? 'none'
                  : undefined,
            }}
            onClick={() => {
              const recordId = recordsToDelete[0];
              const record = records.find((r) => r.id === recordId);
              Object.entries(record).forEach(([field, value]) => {
                dispatch(setRecordField({ field, value }));
              });
              dispatch(setRecordField({ field: 'id', value: recordId }));
              dispatch(setPageId(1));
              dispatch(setEditMode(true));
            }}
          >
            Изменить
          </Button>
        </Box>

        <DataGrid
          // onCellClick={handleOnCellClick
          //   }

          //   if (true) {
          //     dispatch(setRecordField({ field: "datetime", value: '' }));
          //     dispatch(setPageId(1));
          //   } else {
          //     setDoubleClick(0);
          //     setTimeout(() => {
          //       setDoubleClick(0);
          //     }, 1500);
          //   }

          onRowClick={handleOnCellClick}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={(e) => {
            const date = dayjs(e.datetimeView, 'dd, DD.MM.YY, HH:mm', 'ru');
            dispatch(setRecordField({ field: 'info', value: e.info }));
            dispatch(setRecordField({ field: 'id', value: e.id }));
            dispatch(setRecordField({ field: 'name', value: e.name }));
            dispatch(setRecordField({ field: 'service', value: e.service }));
            dispatch(setRecordField({ field: 'source', value: e.source }));
            dispatch(setRecordField({ field: 'info', value: e.info }));
            dispatch(setRecordField({ field: 'status', value: e.status }));
            dispatch(setRecordField({ field: 'datetime', value: date }));
            setPatchRecord(true);
          }}
          rows={records}
          columns={columns}
          // editMode="edit"
          onSelectionModelChange={(e: GridSelectionModel) => {
            dispatch(setSelection(e));
          }}
          pageSize={100}
          rowsPerPageOptions={[100]}
          checkboxSelection
          disableSelectionOnClick
          getRowClassName={(params) => {
            return `super-app-theme--${
              nextToSelectedDayRecordsId.includes(params.row.id) &&
              rejectedIds.includes(params.row.id)
                ? 'Today-rejected'
                : nextToSelectedDayRecordsId.includes(params.row.id) &&
                  !rejectedIds.includes(params.row.id)
                ? 'Today'
                : !nextToSelectedDayRecordsId.includes(params.row.id) &&
                  rejectedIds.includes(params.row.id)
                ? 'Tomorrow-rejected'
                : 'Tomorrow'
            }`;
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
