import React from "react";
import "dayjs/locale/ru";
import { Alert, Snackbar } from "@mui/material";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAppSelector from "../../hooks/useAppSelector";
import { setSuccessArchive } from "../../store/newRecordSlice";
const AlerterArchive = () => {
  const dispatch = useAppDispatch();
  const { successArchive } = useAppSelector(
    (state) => state.newRecord
  );

  const handleCloseSuccess = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setSuccessArchive(false));
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ top: {xs: 0.85*window.document.body.offsetHeight, sm: 0.85*window.document.body.offsetHeight}}}
      open={successArchive}
      autoHideDuration={2000}
      onClose={handleCloseSuccess}
    >
      <Alert
        onClose={handleCloseSuccess}
        severity="success"
      >
        {`Запись перенесена в архив!`}
      </Alert>
    </Snackbar>
  );
};


export default AlerterArchive;