import React from 'react';
import { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import useAppSelector from './hooks/useAppSelector';
import { PAGES_ORDER_ARR } from './constants';
import AddRecord from './pages/AddRecord/AddRecord';
import Spinner from './components/Spinner/Spinner';
import { setPageId } from './store/appSlice';
import useAppDispatch from './hooks/useAppDispatch';
import ManageRecords from './pages/ManageRecords/ManageRecords';
import {
  setSuccess,
  deleteRecords,
  setRejectDialogOpen,
  setRecordField,
  setArchiveDialogOpen,
  moveRecord,
  setSuccessArchive,
  setNeedUpdateTable,
} from './store/newRecordSlice';
import theme from './theme';
import Alerter from './components/Snackbar/Alerter';
import AlertDialog from './components/Alert/AlertDialog';
import ArchiveDialog from './components/Alert/ArchiveDialog';
import AlerterArchive from './components/Snackbar/ArchiveAlerter';

function App() {
  const dispatch = useAppDispatch();
  const { pageId } = useAppSelector((state) => state.app);
  const { finished } = useAppSelector((state) => state.newRecord.record);
  const { record } = useAppSelector((state) => state.newRecord);
  const { recordsToDelete } = useAppSelector((state) => state.newRecord);
  const pageName = PAGES_ORDER_ARR[pageId];

  useEffect(() => {
    if (finished === 'true') {
      dispatch(moveRecord(record));
    }
  }, [finished]);

  const renderPageLayout = () => {
    switch (pageName) {
      case 'addRecord':
        return <AddRecord />;
      case 'manageRecords':
        return <ManageRecords />;
      default:
        return null;
    }
  };
  // document.onload = (e)=>{
  //   dispatch(setPageId(0));
  // };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setPageId(newValue));
    dispatch(setSuccess(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <Spinner />
      <Alerter />
      <AlerterArchive />
      <AlertDialog
        title={'Запись будет отменена'}
        content={'Вы уверены?'}
        onSuccess={() => {
          dispatch(deleteRecords(recordsToDelete));
          dispatch(setRejectDialogOpen(false));
        }}
      />
      <ArchiveDialog
        title={'Запись будет помещена в архив'}
        content={'Вы уверены?'}
        onSuccess={() => {
          dispatch(setRecordField({ field: 'finished', value: 'true' }));
          dispatch(setNeedUpdateTable(true));
          dispatch(setArchiveDialogOpen(false));
          dispatch(setSuccessArchive(false));
          dispatch(setPageId(0));
          
        }}
      />
      <Container component="main" maxWidth="sm">
        {renderPageLayout()}
      </Container>
    </ThemeProvider>
  );
}

export default App;
